<template>
	<div class="mainTem">

		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<el-button type="primary" round icon="el-icon-back" size="mini" @click="goBack()">{{$t('i18nn_ca5a01a5adf20fe7')}}</el-button>
						<span class="tct_tit">{{$t('i18nn_e6a250894a04db4c')}}</span>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">

				</el-col>
			</el-row>
		</div>

		<div class="noticeCon" v-loading="loading_load">
			<h2 class="notice-title">
				
				<span>{{detData.msgText}}</span>
				<span>
					<el-tag size="mini" v-if="'10'===detData.msgType" type="success">{{detData.msgTypeName}}</el-tag>
					<el-tag size="mini" v-else-if="'20'===detData.msgType" type="warning">{{detData.msgTypeName}}</el-tag>
					<el-tag size="mini" v-else type="info">{{detData.msgTypeName}}</el-tag>
				</span>
			</h2>
			<div class="notice-date">
				<span>{{detData.noticeDate}}</span>
			</div>
			<div class="notice-msg">
				<div class="ql-snow">
					<div class="ql-editor" v-html="content">
					</div>
				</div>
			</div>
			<div class="notice-file" v-if='detData.attachments && detData.attachments.length>0'>
				<span>{{$t('i18nn_f5d43732e3f6cf4d')}}</span>：
				<ul>
					<li v-for="(item,index) in detData.attachments" :key="index">
						<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999"
							:fit="'contain'" :src="item.url" :preview-src-list="detData.attachments.map(itemPre=> { return itemPre.url})">
							<div slot="error" class="image-slot">
								<i class="el-icon-document"></i>
							</div>
						</el-image>
						<a :href="item.url" :title="item.url" target="_blank">{{ item.fileName }}</a>

					</li>
				</ul>
			</div>
		</div>


	</div>
</template>
<script>
	import 'quill/dist/quill.snow.css'
	
	import {
		clearObjectVal
	} from '@/utils/utils.js';

	// import dialogFileUpload from '@/components/WarehouseCenter2/components/dialogFileUpload.vue';

	// import whFileView from '@/components/WarehouseCenter2/components/whFileView.vue';
	// import dialogNoticeMoreTextView from '@/components/WarehouseCenter2/NoticeMana/dialogNoticeMoreTextView.vue';

	export default {
		components: {
			// dialogFileUpload,
			// whFileView,
			// dialogNoticeMoreTextView
		},
		props: {
			// mobile:"",
			isSel: {
				default: function() {
					return false
				},
				type: Boolean
			},
		},
		data() {
			return {
				id: "",
				// CateValue: [],
				loading: false,

				loading_load: false,
				detData: {},
				content: "",
			};
		},
		activated() {
			// this.initData();
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			this.initData();
		},
		methods: {
			initData() {
				this.id = this.$route.query.id;
				this.detData = {};
				this.content = "";
				this.getBaseData();
				// this.getConTextData(this.id);
			},
			goBack() {
				// console.log('window.history.length',window.history.length);
				// if (window.history.length > 2) {
				this.$router.go(-1);
				// } else {
				// 	this.$router.push({
				// 		name: 'WhDropShippingDashboard'
				// 	});
				// }
			},

			//请求分页数据
			getBaseData() {
				// let _this = this;
				this.loading_load = true;

				this.$http
					.get(this.$urlConfig.WhNoticeDet+'/'+this.id, {})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						// console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.detData = data.data;
						this.getConTextData(this.detData.msgTextKey);
					})
					.catch(error => {
						console.log(error);
						// console.log('分页，请求失败');
						this.loading_load = false;
					});
			},
			//请求
			getConTextData(key) {
				// let _this = this;
				this.loading_load = true;

				this.$http
					.get(this.$urlConfig.WhNoticeMoreText + "?keyId=" + key, {})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						// console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.content = data.data;
					})
					.catch(error => {
						console.log(error);
						// console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// @import url(@/assets/css/client_module.less);
	.noticeCon {
		padding: 20px;
		background: #fff;
		line-height: 200%;
		.notice-title {
			text-align: center;
			font-size: 28px;
			line-height: 40px;
			font-weight:bold;
			color:#333;
		}
		.notice-date {
			text-align: center;
			font-size: 12px;
			color:#999;
		}
		.notice-msg {
			font-size: 14px;
			color:#666;
		}
		.notice-file {
			
		}
	}
</style>
